<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-alert
          text
          dense
          type="error"
          v-if="sessionTimeout"
        >
          {{ $t('message.session_expired') }}
        </v-alert>

        <p v-if="errors.length">
          <v-alert
            text
            dense
            type="error"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <v-alert
          text
          dense
          color="teal"
          border="left"
          type="success"
          v-if="successMessage"
        >
          {{ successMessage }}
        </v-alert>

        <div class="callback m-auto">
          {{ $t('message.redirecting') }}
          <div class="m-auto mt-4 dot-flashing"></div>
        </div>

        <v-alert
          text
          dense
          type="error"
          v-if="error !== null"
        >
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import LoginLayout from '../../layouts/LoginLayout'
import config from '../../config'
import Vue from 'vue'

export default {
  name: 'Login',
  data: () => ({
    valid: false,
    showPassword: false,
    successMessage: null,
    errors: [],
    sessionTimeout: false,
    autofill: false,
    intervalDetectAutofill: null
  }),
  created () {
    this.$emit('update:layout', LoginLayout)

    document.title = config.title + ' - ' + this.$t('message.login')
  },
  mounted () {
    this.checkTokens()
  },
  computed: {
    error () {
      return this.$store.getters.getError
    }
  },
  methods: {
    checkTokens () {
      const parsedParams = {}

      this.$route.hash.split('&')
        .map(part => part.replace(/#/, ''))
        .forEach(param => {
          const parts = param.split('=')
          parsedParams[parts[0]] = parts[1]
        })

      Vue.prototype.$http
        .post(config.cffAutUrl, {}, {
          headers: {
            'x-fiducial-token': 'Bearer ' + parsedParams.id_token,
            'x-fiducial-at': 'Bearer ' + parsedParams.access_token
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data
            const token = data.token

            this.$store.dispatch('loginAUT', { token })
          }
        })
        .catch(e => {
          const response = e.response

          const data = response.data

          if (response.status === 403) {
            this.errors = data.violations

            setTimeout(() => {
              this.$router.push({ name: 'Login' })
            }, 3000)
          }
        })
    }
  }
}
</script>
